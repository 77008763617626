export interface Role<T = string> extends RoleProperties {
  _id?: T;
  id?: T;
}

export interface RoleProperties extends IPermission {
  schoolId: string;
  name: string;
  type: RoleType;
  lastUpdated?: Date;
}

export interface IPermission {
  access?: number;
  account?: number;
  allCourses?: boolean;
  allCoursesPermission?: number;
  courses?: Record<string, number> | null;
  exams?: number;
  integrations?: number;
  members?: number;
  messages?: number;
  settings?: number;
  yourArea?: number;
  team?: number;
  deleteSchool?: number;
  spaces?: number;
  dashboard?: number;
  sidenav?: number;
  pages?: number;
  portals?: number;
  home?: number;
}

export type PermissionModule = keyof Omit<IPermission, 'allCourses'>;

export type RoleType = 'default' | 'custom';

export enum Permission {
  NO_ACCESS = 0,
  READ = 1,
  CREATE = 2,
  EDIT = 4,
  DELETE = 8,
}

export const PermissionTable: Record<keyof typeof Permission, Permission> = {
  CREATE: Permission.CREATE,
  DELETE: Permission.DELETE,
  EDIT: Permission.EDIT,
  NO_ACCESS: Permission.NO_ACCESS,
  READ: Permission.READ,
};
