import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { QueryEntityCustom } from '@memberspot/frontend/shared/data-access/common';

import {
  SchoolAdminPermissionState,
  SchoolAdminPermissionStore,
} from './school-admin-permission.store';

@Injectable({ providedIn: 'root' })
export class SchoolAdminPermissionQuery extends QueryEntityCustom<SchoolAdminPermissionState> {
  constructor(
    protected store: SchoolAdminPermissionStore,
    protected routerQuery: RouterQuery,
  ) {
    super(store, routerQuery);
  }
}
