import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { SchoolAdminPermission } from '@memberspot/shared/model/permission';

export interface SchoolAdminPermissionState
  extends EntityState<SchoolAdminPermission, string>,
    ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'school-admin-permission', idKey: '_id' })
export class SchoolAdminPermissionStore extends EntityStore<SchoolAdminPermissionState> {
  constructor() {
    super();
    this.setLoading(false);
  }
}
